var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c("h2", [_vm._v(_vm._s(_vm.title))]),
      _c(
        "app-form",
        {
          attrs: {
            id: "office-form",
            submitAction: _vm.create,
            submitSuccessAction: _vm.createSuccess,
            validationOptions: _vm.validationOptions
          }
        },
        [
          _c(
            "div",
            { staticClass: "form-group" },
            [
              _c("app-textbox", {
                attrs: { id: "name", placeholder: _vm.$t("shared.name") },
                model: {
                  value: _vm.office.name,
                  callback: function($$v) {
                    _vm.$set(_vm.office, "name", $$v)
                  },
                  expression: "office.name"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "form-group" },
            [
              _c("app-timepicker", {
                attrs: {
                  id: "openingTime",
                  placeholder: _vm.$t("offices.openingTime"),
                  openOnFocus: true
                },
                model: {
                  value: _vm.office.openingTime,
                  callback: function($$v) {
                    _vm.$set(_vm.office, "openingTime", $$v)
                  },
                  expression: "office.openingTime"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "form-group" },
            [
              _c("app-timepicker", {
                attrs: {
                  id: "closingTime",
                  placeholder: _vm.$t("offices.closingTime"),
                  openOnFocus: true
                },
                model: {
                  value: _vm.office.closingTime,
                  callback: function($$v) {
                    _vm.$set(_vm.office, "closingTime", $$v)
                  },
                  expression: "office.closingTime"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "form-group" },
            [
              _c("app-dropdownlist", {
                attrs: {
                  id: "countryCode",
                  placeholder: _vm.$t("address.country"),
                  dataSource: _vm.countries,
                  fields: { text: "name", value: "code" }
                },
                on: { input: _vm.onCountryCodeChanged },
                model: {
                  value: _vm.office.countryCode,
                  callback: function($$v) {
                    _vm.$set(_vm.office, "countryCode", $$v)
                  },
                  expression: "office.countryCode"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "form-group" },
            [
              _c("app-textbox", {
                attrs: {
                  id: "streetAddress",
                  placeholder: _vm.$t("address.address")
                },
                model: {
                  value: _vm.office.streetAddress,
                  callback: function($$v) {
                    _vm.$set(_vm.office, "streetAddress", $$v)
                  },
                  expression: "office.streetAddress"
                }
              }),
              _c("app-textbox", {
                staticStyle: { "margin-top": "6px" },
                attrs: { id: "extendedAddress" },
                model: {
                  value: _vm.office.extendedAddress,
                  callback: function($$v) {
                    _vm.$set(_vm.office, "extendedAddress", $$v)
                  },
                  expression: "office.extendedAddress"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "form-group" },
            [
              _c("app-textbox", {
                attrs: {
                  id: "postalCode",
                  placeholder: _vm.$t("address.postalCode")
                },
                model: {
                  value: _vm.office.postalCode,
                  callback: function($$v) {
                    _vm.$set(_vm.office, "postalCode", $$v)
                  },
                  expression: "office.postalCode"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "form-group" },
            [
              _c("app-textbox", {
                attrs: { id: "city", placeholder: _vm.$t("address.city") },
                model: {
                  value: _vm.office.city,
                  callback: function($$v) {
                    _vm.$set(_vm.office, "city", $$v)
                  },
                  expression: "office.city"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "form-group" },
            [
              _c("app-textbox", {
                attrs: { id: "region", placeholder: _vm.$t("address.region") },
                model: {
                  value: _vm.office.region,
                  callback: function($$v) {
                    _vm.$set(_vm.office, "region", $$v)
                  },
                  expression: "office.region"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "form-group" },
            [
              _c("app-dropdownlist", {
                attrs: {
                  id: "currencyCode",
                  placeholder: _vm.$t("shared.currency"),
                  dataSource: _vm.currencies,
                  fields: { text: "name", value: "code" }
                },
                model: {
                  value: _vm.office.currencyCode,
                  callback: function($$v) {
                    _vm.$set(_vm.office, "currencyCode", $$v)
                  },
                  expression: "office.currencyCode"
                }
              })
            ],
            1
          ),
          _c("app-form-buttons", {
            attrs: {
              addSubmitButton: true,
              submitButtonText: "shared.create",
              addCancelButton: true,
              cancelAction: _vm.cancel
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }